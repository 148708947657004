module services {
    export module tariff {
        export class dutyTypeService implements interfaces.tariff.IDutyTypeService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getDropdownList(countryId: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "DutyType/GetForDropdown", {
                    countryId: countryId
                });
            }

            getForTariffSearchDropDown(countryId: number): ng.resource.IResourceClass<interfaces.tariff.IDutyTypeDropDown> {
                return this.$resource<interfaces.tariff.IDutyTypeDropDown>(this.ENV.DSP_URL + "DutyType/GetForTariffSearchDropDown", {
                    countryId: countryId
                });
            }

            getForTariffSearchDropDownByType(countryId: number, codeTypeId: number): ng.resource.IResourceClass<interfaces.tariff.IDutyTypeDropDown> {
                return this.$resource<interfaces.tariff.IDutyTypeDropDown>(this.ENV.DSP_URL + "DutyType/GetForTariffSearchDropDownByType", {
                    countryId: countryId,
                    codeTypeId: codeTypeId
                });
            }

            //Gets default for a traiff country
            getDefaultForCountryDropDown(countryId: number): ng.resource.IResourceClass<interfaces.tariff.IDutyTypeDropDown> {
                return this.$resource<interfaces.tariff.IDutyTypeDropDown>(this.ENV.DSP_URL + "DutyType/GetDefaultForCountryDropDown", {
                    countryId: countryId
                },{
                        get: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                });
            }

            //Gets default for a traiff country
            getDefaultForCountryDropDownByType(countryId: number, codeTypeId): ng.resource.IResourceClass<interfaces.tariff.IDutyTypeDropDown> {
                return this.$resource<interfaces.tariff.IDutyTypeDropDown>(this.ENV.DSP_URL + "DutyType/GetDefaultForCountryDropDownByType", {
                    countryId: countryId,
                    codeTypeId: codeTypeId
                }, {
                        get: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }

        }
    }
    angular.module("app").service("dutyTypeService", services.tariff.dutyTypeService);
}